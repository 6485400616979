import React  from "react";
import PropTypes from "prop-types";
import tw from "tailwind.macro";
import styled from "styled-components/macro";
import { Divider } from "../elements/Dividers";
import Content from "../elements/Content";
import { waveAnimation, UpAndfadeOut } from "../styles/animations";
import { ResponsiveBlob } from "../components/Blobs";
import { colors } from "../../tailwind";
import { media } from "../styles/utils";

const WaveWrapper = styled.div`
  ${tw`absolute pin-b w-full`};
  transform: matrix(1, 0, 0, -1, 0, 0);
`;

const InnerWave = styled.div`
  ${tw`relative h-full`};
  svg {
    width: 100%;
    height: 40vh;
  }
  path {
    ${waveAnimation("20s")};
  }
`;

const Contact = ({ children, offset }) => (
  <>
    <Divider fill={colors["blue-grey"]} speed={0.2} offset={offset}>
      <WaveWrapper>
        <InnerWave
        >
          <svg
            // style={{ transform: "rotate(180deg)", position: "absolute", bottom: "0", maxHeight: "250px", minHeight: "200px" }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 800 338.05"
            preserveAspectRatio="none"
          >
            <path>
              <animate
                attributeName="d"
                values="M 0 100 Q 250 50 400 200 Q 550 350 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 200 150 400 200 Q 600 250 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 150 350 400 200 Q 650 50 800 300 L 800 0 L 0 0 L 0 100 Z"
                repeatCount="indefinite"
                dur="30s"
              />
            </path>
          </svg>
        </InnerWave>
      </WaveWrapper>
    </Divider>
    <Content
      speed={0.4}
      offset={offset}
      css={`
        ${media.sm`margin-top: 30%;`}
      `}
    >
      {children}
    </Content>
    <Divider speed={0.1} offset={offset}>
      <UpAndfadeOut delay={3}>
        <ResponsiveBlob
          size={3}
          left="25%"
          top="90%"
          color={colors["blue-grey"]}
        />
      </UpAndfadeOut>
      <UpAndfadeOut>
        <ResponsiveBlob
          size={4}
          left="65%"
          top="85%"
          color={colors["blue-grey"]}
        />
      </UpAndfadeOut>
    </Divider>
  </>
);

export default Contact;

Contact.propTypes = {
  children: PropTypes.node.isRequired,
  offset: PropTypes.number.isRequired
};
